<template>
  <div class="full-container">app-h5</div>

</template>

<script>

export default {
  name: 'Home',
  data() {
    return {

    }
  },
  methods: {

  },
  mounted(){

  }
}
</script>
<style scoped lang="less"></style>
